// 高效学习
<template>
  <div class="content">
    <img
      src="../assets/imgs/gaoxiaoxuexi/gaoxiaoxuexi_01.jpg"
      @click="toIndex"
    />
    <img src="../assets/imgs/gaoxiaoxuexi/gaoxiaoxuexi_02.jpg" />
    <img src="../assets/imgs/gaoxiaoxuexi/gaoxiaoxuexi_03.jpg" />
    <img src="../assets/imgs/gaoxiaoxuexi/gaoxiaoxuexi_04.jpg" />
  </div>
</template>
<script>
export default {
  methods: {
    //去首页
    toIndex() {
      location.href = "http://www.wzclxx.com/";
    },
  },
};
</script>
<style scoped>
@import "../assets/css/common.css";
</style>